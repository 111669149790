import React, { useContext, useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import { useFetch } from '../../hooks/useFetch';
import { AuthFormContainerPage, PwdEye, SubmitFormButton } from '../controls/UIControls';
import { patterns } from '../../assets/patterns';
import { Link, useNavigate } from 'react-router-dom';
import { 
        LABEL_EMAIL_PLACEHOLDER,
        LABEL_PASSWORD_PLACEHOLDER,
        MESSAGE_GENERIC_ERROR, 
        MESSAGE_EMAIL_INVALID,  
        MESSAGE_EMAIL_REQUIRED,
        MESSAGE_PASSWORD_REQUIRED, 
        MESSAGE_USER_BLOCKED,
        MESSAGE_USER_INVALID,
         } 
from '../../assets/constants';
import { useAuthToken } from '../../hooks/useAuthToken';
import { UserContext } from '../../context/UserContext';
import { useUserPlan } from '../../hooks/useUserPlan';
import { apiConfig } from '../../api/apiConfig';
//import { useCookies } from 'react-cookie';

const ErrorMessage = (props) => {
    return (
        <div className={`auth__errorMessage ${props.className}`}>
            <span role="alert" dangerouslySetInnerHTML={{ __html: props.message }}>
            </span>
        </div>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página para iniciar sesión
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface LoginForm
 */
const LoginForm =  (props) => {
    const { register, handleSubmit, formState: { errors }, control, setError, watch } = useForm();
    const [fetchData, handleFetch] = useFetch();
    const [loginError, setLoginError] = useState('');
    const {user, setUser} = useContext(UserContext);
    //const [cookies, setCookie] = useCookies([USER_INFO]);
    const {deleteUserHasPlan, deleteStickyBanner} = useUserPlan();
    const navigate = useNavigate();
    const {authToken, removeAuthToken, removeSessionUser}= useAuthToken();
    const LOGIN_ATTEMPTS = 3;
    const {checkUserPlan} = useUserPlan();
        
    useEffect(()=> {
        let subs = null;
        if(loginError!=='') {
            subs = watch(() => setLoginError(''));
        }
        return () => subs?subs.unsubscribe():subs;
    },[loginError, watch]);
    const {setSessionUser} = useAuthToken(props.isAdmin);

    const handleLogin = (formValues) => {
        console.log(formValues);
        setLoginError('');
        let url = 'users/login';
        let data = {
                    email:formValues.email, 
                    password: formValues.password, 
                    companyId: apiConfig.company,
                    rememberme:formValues.rememberme,
                    strategy:'local'
                   }

        const response = handleFetch({
            url:url,
            method: 'POST', 
            data: data
        });
        response
            .then((response) => {
                props.setAuthToken(response.accessToken,formValues.rememberme, response.user);
                setUser(response.user);
                setSessionUser(JSON.stringify(response.user),formValues.rememberme);
                checkUserPlan(response.accessToken)
                .then(navigate('/cursos'));
                
                /*if(response.user.companyId === apiConfig.company){
                    props.setAuthToken(response.accessToken,formValues.rememberme, response.user);
                    setUser(response.user);
                    setSessionUser(JSON.stringify(response.user),formValues.rememberme);
                    checkUserPlan(response.accessToken)
                    .then(userHasPlan => navigate(userHasPlan?'/cursos':'/'));
                }else{
                    removeAuthToken();
                    removeSessionUser();
                    deleteUserHasPlan();*/
                    /*const response = handleFetch({
                        url:'users/logout',
                        method: 'POST',
                        headers: {Authorization: `Bearer ${authToken}`} 
                    });
                    response
                    .then( r => {
                        navigate("/ingreso");
                    },
                    error => {
                        console.log(error);  
                    })
                    .finally (() => deleteStickyBanner());*/
                 /*   navigate('/')
                }*/
            },
            error => {
                if(error.code === 400){
                    setLoginError(error.message);
                }
                if(error.errors && Array.isArray(error.errors)) {
                    let e = error.errors[0];
                    switch(e.id) {
                        case 1: // Login fallido
                            let errMessage = e.message;
                            const eal = error.errors.find(e => e.login_attempts!==undefined);
                            if(eal!==undefined) {
                                errMessage+=`<br/>${eal.login_attempts} de ${LOGIN_ATTEMPTS} intentos.`;
                                if(eal.login_attempts>=LOGIN_ATTEMPTS) errMessage+=MESSAGE_USER_BLOCKED;
                            }
                            setLoginError(errMessage);
                            break;    
                        case 2: // Clave/correo no validos
                            setError('email',{message:e.message},{shouldFocus:true});
                            break;
                        case 3:
                            setLoginError(MESSAGE_USER_BLOCKED);
                            break;
                        default:
                            setLoginError(e.message);
                    }
                } else {
                    setLoginError((error.code==401)?MESSAGE_USER_INVALID:MESSAGE_GENERIC_ERROR);
                }
                
            })
    }
    return (
        <form onSubmit={ handleSubmit(handleLogin) }>
            <div className="mb-1">
                <label className='auth__form-label'>Correo</label>
                <input 
                    type='text' 
                    name='email'
                    className={`form-control auth__input 
                                ${errors.email?"auth__input--error":""}`}
                    placeholder= { LABEL_EMAIL_PLACEHOLDER }
                    {...register("email", { required: MESSAGE_EMAIL_REQUIRED,
                                            pattern: {
                                                value: patterns.email,
                                                message: MESSAGE_EMAIL_INVALID
                                            } 
                                        })}
                />
               <ErrorMessage message={errors.email?errors.email.message:''}/> 
            </div>
            <div className="mb-1">
                <label className='auth__form-label'>Contraseña</label>
                <Controller
                    name="password"
                    control={control}
                    rules={{ required: MESSAGE_PASSWORD_REQUIRED }}
                    render={({field: { onChange, onBlur, name}, fieldState: {invalid} }) => {
                        return (
                        <PwdEye 
                            className = {`form-control auth__input ${invalid?"auth__input--error":""}`}
                            placeholder={LABEL_PASSWORD_PLACEHOLDER}
                            onChange={onChange}
                            onBlur={onBlur}
                            name={name}
                            error={invalid}
                        >
                        </PwdEye>)
                        }
                    }
                />
                <ErrorMessage message={errors.password?errors.password.message:''}/> 
            </div>
            {
                    <div className="row fw-bold mt-3">
                        <div className="col-12 col-md-8 order-md-2 text-md-end">
                            <Link className='auth__link' to="/recuperar-contrasena">¿Olvidaste tu contraseña?</Link>
                        </div>
                        <div className="col-12 col-md-4 order-md-1">
                            <div className="form-check">
                                    <input className="form-check-input auth__form-check" 
                                        type="checkbox" 
                                        value=""
                                        id='remember_me'
                                        {...register("rememberme") }
                                    />
                                    <label className='form-check-label auth__form-label' htmlFor='remember_me' > Recordarme </label>
                                </div>
                            </div>
                    </div>
            }
            <SubmitFormButton main_title="Iniciar sesión" wait_title="Ingresando..." loading={fetchData.loading}></SubmitFormButton>
            <ErrorMessage className='mb-1 text-center auth__errorMessage--2lines' message={loginError}/> 
        </form>
    )
}

export const Login = ({setAuthToken, isAdmin}) => {
    const loginForm = (<>
                        <LoginForm  setAuthToken={ setAuthToken } isAdmin={isAdmin} />
                    </>)
    return (<AuthFormContainerPage title="Bienvenid@ de vuelta" 
                content={loginForm}></AuthFormContainerPage>);
} 


export const LoginPage =  () => {
    const {setAuthToken, authRedirect} = useAuthToken();

    useEffect(()=> {
        authRedirect(true);
    },[]);
    return (<Login setAuthToken={setAuthToken}></Login>);
}
