// Your web app's Firebase configuration
export const apiConfig = {
    // Api Key para conexión
    header: {
        'Content-Type': 'application/json',
        'x-api-key': 'L8vDTV7wJMa6s8BGCsbC8uL7cFGu08DybuQnkwAKjpULZDpFcShAoOOv31Bf'
    },
    company: 5,
    baseUrl: 'https://eanx.co/api/',
    mercadoPago: {
        returnUrl: 'http://eanx-web-b2b-prod.eastus.cloudapp.azure.com/cuenta-creada'

    }   
  };